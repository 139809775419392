import Vue from 'vue';
import VueRouter from 'vue-router'
import LoginForm from '../views/Login.vue'
import Empty from '../views/Empty.vue'
import Dashboard from '../views/Dashboard.vue'

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		beforeEnter (to, from, next) {
      let user = window.localStorage.getItem('feathers-jwt')
      if (user) {
        // let d = JSON.parse(user)
        next(`/dashboard`)
      } else {
        next('/login')
      }
    }
	},
	{
		path: '/login',
		name: 'Login',
		component: LoginForm
	},
	{
		path: '/dashboard',
		name: 'Dashboard',
		component: Dashboard
	},
	{
		path: '/nomination',
		name: 'Nomnination',
		component: () => import(/* webpackChunkName: "NominationForm" */ '@/views/Nomination.vue')
	},
	{
		path: '/users',
		name: 'UserList',
		component: () => import(/* webpackChunkName: "UserMaintenance" */ '@/views/Users.vue')
	},
	{
		path: '/users/:id/edit', // to edit permissions
		name: 'UserEdit',
		component: Empty
	},
	{
		path: '/nominations',
		name: 'NominationList',
		component: Empty
	},
	{
		path: '/nominations/:id/edit',
		name: 'NominationEdit',
		component: Empty
	},
	{
		path: '/nominations/:id/approve',
		name: 'NominationApprove',
		component: Empty
	},
	{
		path: '/nominations/:id',
		name: 'NominationView',
		component: Empty
	},
	{
		path: '/queues',
		name: 'Queues',
		component: () => import(/* webpackChunkName: "QueuesList" */ '@/views/Queues.vue')
	},
	{
		path: '/assignments',
		name: 'Assignments',
		component: () => import(/* webpackChunkName: "AssignmentList" */ '@/views/Assignments.vue')
	},
	{
		path: '*',
		component: Empty
	}
]

const router = new VueRouter({
	mode: 'history',
	base: '/',
	routes
})

export default router
