import Vue from 'vue'
import App from './App.vue'

import router from './router'
import store from './store'
import axios from 'axios'

import Vuetify from 'vuetify/lib'
import colors from 'vuetify/lib/util/colors'

Vue.config.productionTip = false;

Vue.prototype.$http = axios;
Vue.use(Vuetify);

const vuetify = new Vuetify({
	theme: {
		light: {
			primary: colors.grey.darken5,
			secondary: '#363636',
			anchor: colors.grey.darken1
		}
	}
})

new Vue({
	router,
	store,
	vuetify,
  render: h => h(App),
}).$mount('#app')
