import feathersClient, { makeServicePlugin, BaseModel } from '../../feathers-client'

class Status extends BaseModel {
  constructor(data, options) {
    super(data, options)
  }
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = 'Status'
  // Define default properties here
  static instanceDefaults() {
    return {
      nomination_id: null,
      changed_by: null,
      assigned_to: null,
      reason: null,
      status: null,
      active: 1
    }
  }
}
const servicePath = 'status'
const servicePlugin = makeServicePlugin({
  idField: 'id',
  Model: Status,
  service: feathersClient.service(servicePath),
  servicePath
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
})

export default servicePlugin
