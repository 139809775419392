<template>
	<v-app class="application">
    <v-app-bar app color="#363636" dark>
			<div id="5sl-logo">
        <img src="/5sl.svg" id="logo" />
			</div>
			<div id="titles">
				<div id="title">TRMN Awards Tracking</div>
				<div id="subtitle">Bureau of Personnel</div>
			</div>
			<v-spacer></v-spacer>
			<v-app-bar-nav-icon @click.stop="showMenu=!showMenu"></v-app-bar-nav-icon>
		</v-app-bar>

		<v-main id="main-content">
      <v-navigation-drawer fixed temporary right v-model="showMenu">
				<v-list>
					<v-list-item to="/dashboard">
						<v-list-item-title>
							<span class="nav-item">Dashboard</span>
						</v-list-item-title>
					</v-list-item>
					<v-list-item to="/nomination">
						<v-list-item-title>
							<span class="nav-item">Add Nomination</span>
						</v-list-item-title>
					</v-list-item>
					<v-list-item to="/users" v-if="user && user.role==='ADMIN'">
						<v-list-item-title>
							<span class="nav-item">User Maintenance</span>
						</v-list-item-title>
					</v-list-item>
					<v-list-item to="/queues" v-if="user && user.role==='ADMIN'">
						<v-list-item-title>
							<span class="nav-item">Queue Maintenance</span>
						</v-list-item-title>
					</v-list-item>
					<v-list-item to="/assignments" v-if="user && user.role==='ADMIN'">
						<v-list-item-title>
							<span class="nav-item">Queue Assignment Maintenance</span>
						</v-list-item-title>
					</v-list-item>
					<v-list-item>
						<v-list-item-title>
							<span class="nav-item" @click="signout">Logout</span>
						</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-navigation-drawer>

			<v-container fluid>
        <router-view></router-view>
			</v-container>
		</v-main>
	</v-app>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'App',
	data: () => {
		return {
			showMenu: false
		}
	},
  components: {
  },
  computed: {
    ...mapGetters('auth', ['user', 'isAuthenticated'])
  },
  methods: {
    ...mapActions('auth', ['authenticate', 'logout']),
		signout() {
			this.logout().then(() => {
				this.showMenu=false;
				this.$router.push('/login');
			})
		}
  },
  mounted() {
    this.authenticate()
    .then(() => {
      this.$router.push('/dashboard');
    })
    .catch(() => {
      console.error('Authentication failed');
			this.$router.push('/login');
    })
  }
}
</script>

<style>
@font-face {
font-family: 'Decalotype Light';
font-style: normal;
font-weight: normal;
src: local('Decalotype Light'), url('./assets/decalotype/Decalotype-Light.woff') format('woff');
}

@font-face {
  font-family: 'Incised Nord';
  font-style: normal;
  font-weight: normal;
  src: local('Incised Nord'), url('./assets/Incised-901-Nord-BT.ttf')
}

#app {
	font-family: "Decalotype Light", Avenir, Helvetica, Arial, sans-serif;
	font-size: 1.1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#5sl-logo {
	height: 56px;
  margin-right: 8px;
}

img#logo {
  height: 56px;
  margin: 6px 8px 6px 0;
}

.application {
  display: flex;
	flex-direction: column;
}

#main-content .v-main__wrap {
  display: flex;
	flex-direction: row;
	}

	#titles {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-grow: 1;
  font-family: "Incised Nord", "Droid Serif", serif;
}

#title {
	font-size: 1.25rem;
}

#subtitle {
	font-size: .9rem;
}

pre {
	max-width: 80ch;
	white-space: break-spaces;
}
</style>
