<template>
  <div>
    This page is not yet ready.
  </div>
</template>

<script>
export default {
  name: 'EmptyView'
}
</script>
