<template>
  <div class="d-flex flex-row justify-center mt-8">
		<v-snackbar v-model="badLogin" :timeout="5000">
			Username/password combination not found
			<v-btn text @click="badLogin=false">
				Close
			</v-btn>
		</v-snackbar>
    <v-card :min-width="width">
      <v-card-title>Login</v-card-title>
      <v-card-text>
        <v-form v-model="valid" v-if="!loading">
          <v-container>
            <v-row>
              <v-text-field
                v-model="user.email"
                :rules="emailRules"
                label="Email"
                required
              ></v-text-field>
            </v-row>
            <v-row>
              <v-text-field
                v-model="user.password"
                :rules="passwordRules"
                type="password"
                label="Password"
                required
              ></v-text-field>
            </v-row>
            <v-row class="d-flex flex-row justify-center">
              <v-btn
								:disabled="!valid"
                elevation="2"
                @click="login"
              >
                Login
              </v-btn>
            </v-row>
          </v-container>
        </v-form>
				<v-container v-if="loading" class="d-flex align-center">
				<v-progress-circular
					:size="70"
					:width="7"
					indeterminate
					color="primary"
					v-if="loading"
				></v-progress-circular>
				</v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
export default {
  name: "LoginForm",
  data () {
    return {
      valid: false,
			user: {
				password: '',
				email: ''
			},
			badLogin: false,
			passwordRules: [
        v => !!v || 'Password is required'
			],
			emailRules: [
        v => !!v || 'Email is required',
				v => /.+@.+/.test(v) || 'Email must be valid'
			]
		}
  },
	computed: {
		...mapState('auth', { loading: 'isAuthenticatePending' }),
    width () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return "95%"
        case 'sm': return "95%"
        case 'md': return "70%"
        case 'lg': return "60%"
        default: return "50%"
      }
    }
  },
	methods: {
		...mapActions('auth', ['authenticate']),
    login () {
			if (this.valid) {
				console.log("Login...");
				this.authenticate({
					strategy: 'medusa',
					...this.user
				})
				.then(() => {
					this.$router.push('/dashboard');
				})
				.catch((err) => {
					console.error(err);
				});
			}
		}
	}
}
</script>
