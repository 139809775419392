import feathersClient, { makeServicePlugin, BaseModel } from '../../feathers-client'

class QueueAssignment extends BaseModel {
  constructor(data, options) {
    super(data, options)
  }
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = 'QueueAssignment'
  // Define default properties here
  static instanceDefaults() {
    return {
			member: 0,
			queue: 0,
			beginning: new Date(),
			ending: null,
			approver: false,
			active: true,
    }
  }
}
const servicePath = 'queue-assignments'
const servicePlugin = makeServicePlugin({
  idField: 'id',
  Model: QueueAssignment,
  service: feathersClient.service(servicePath),
  servicePath
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
})

export default servicePlugin
