<template>
  <div class="entry">
    <div class="status">{{ history.status || '' }}</div>
    <div class="who">{{ history.changed_by ? `${history.changed_by.first_name} ${history.changed_by.last_name}` : '' }}</div>
    <div class="date">{{ fmtDate(history.updated) || '' }}</div>
    <div class="note">{{ history.reason || '' }}</div>
  </div>
</template>

<script>
export default {
  name: 'NominationHistory',
  props: ['history'],
  methods: {
    fmtDate(when) {
      let dt = new Date(when);
      let months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN',
                    'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']
      return `${dt.getDate()} ${months[dt.getMonth()]} ${dt.getFullYear()}`
    }
  }
}
</script>

<style scoped>
.entry {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.note {
  grid-column-start: 1;
  grid-column-end: 4;
}
</style>
