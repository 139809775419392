<template>
  <div id="review">
    <div class="text-center ma-2">
      <v-snackbar
        v-model="snackbar"
      >
        {{ snackmsg }}

        <template v-slot:action="{ attrs }">
          <v-btn
            color="pink"
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </div>
    <v-card>
      <v-card-text>
        <!-- {{ JSON.stringify(value) }} -->
        <div id="data" v-if="value">
          <div class="label">Nominee</div>
          <div class="val">{{ value.nominee_first_name }} {{ value.nominee_last_name }}</div>
          <div class="label">Nominee RMN ID</div>
          <div class="val">{{ value.nominee_member_id }}</div>
          <div class="label">Nominee Branch</div>
          <div class="val">{{ value.nominee_branch }}</div>
          <div class="label">Award</div>
          <div class="val">{{ value.award_name.name }}</div>
          <div class="label">Nominated By</div>
          <div class="val">{{ value.nominator.first_name }} {{ value.nominator.last_name }}</div>
          <div class="label">Nominated on Behalf Of</div>
          <div class="val">{{ value.out }}</div>
          <div class="label">Nomination Status</div>
          <div class="val">{{ value.status }}</div>
          <div class="label">Nomination Text</div>
          <div class="val"><pre>{{ value.nomination_text }}</pre></div>
        </div>
        <v-form v-if="value">
          <v-radio-group v-model="disposition">
            <v-radio value="award" v-if="value.status==='IA APPROVAL'">
              <template slot="label">Award</template>
            </v-radio>
            <v-radio value="approve" v-if="value.status==='AWARDS APPROVAL'">
              <template slot="label">
                Approve for awarding
              </template>
            </v-radio>
            <v-radio value="forward" v-if="value.status==='SUBMITTED'">
              <template slot="label">
                <span>Approve and forward to IA</span>
                <v-select
                  v-model="new_queue"
                  class="ml-2"
                  :items="queues"
                  dense
                  :full-width="false"
                ></v-select>
              </template>
            </v-radio>
            <v-radio value="suggest" v-if="value.status!=='IA APPROVAL' && value.status !== 'HOLD'">
              <template slot="label">
                Hold, suggest alternate award
                <v-select
                  v-model="new_award"
                  class="ml-2"
                  :items="branchAwards"
                  dense
                ></v-select>
              </template>
            </v-radio>
            <v-radio value="update" v-if="value.status==='HOLD'">
              <template slot="label">Update to
              <v-select
                v-model="new_award"
                class="ml-2"
                :items="branchAwards"
                dense
              ></v-select>
              </template>
            </v-radio>
            <v-radio value="deny" v-if="value.status!=='IA APPROVAL' && value.status !== 'HOLD'">
              <template slot="label">Deny</template>
            </v-radio>
            <v-radio value="none">
              <template slot="label">Note only; take no action</template>
            </v-radio>
          </v-radio-group>
          <v-textarea
            label="Note"
            v-model="note"
            :rules="rules"
            counter
            auto-grow
          ></v-textarea>
        </v-form>
        <v-btn small @click="submit" class="mr-1">Submit</v-btn>
        <v-btn small @click="$emit('cancel')" class="ml-1">Cancel</v-btn>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: "NominationReview",
  props: ['value'],
  data() {
    return {
      rules: [v => v.length <= 250 || 'Max 250 characters'],
      disposition: 'none',
      new_queue: null,
      new_award: null,
      note: '',
      snackbar: true,
      snackmsg: 'Init',
    }
  },
  methods: {
    cleanNom(nom) {
      let tmp = nom;
      delete tmp.name;
      delete tmp.award_name;
      delete tmp.in;
      delete tmp.out;
      delete tmp.test;
      delete tmp.nominator;
      return tmp;
    },
    submit() {
      if (this.disposition === 'none') {
        // add status/history entry with note 
        let test = new this.$FeathersVuex.api.Status();
        test.status = "NOTE";
        test.reason = this.note;
        test.changed_by = this.authUser.id;
        test.nomination_id = this.value.id;
        test.assigned_to = this.value.queue;
        test.save()
          .then(() => {
            const vm = this;
            this.snackmsg = "Note saved";
            this.snackbar = true;
            setTimeout(() => { vm.snackbar = false; }, 5000)
          })
          .catch((err) => {
            const vm = this;
            this.snackmsg = "There was an error saving the note";
            this.snackbar = true;
            setTimeout(() => { vm.snackbar = false; }, 5000)
            console.error(`Error saving note: ${err}`)
          })
        // console.log('NOTE ONLY', JSON.stringify(test));
        
      }
      if (this.disposition === 'deny') {
        // change the status of the nomination
        let nom = new this.$FeathersVuex.api.Nomination(this.cleanNom(this.value));
        nom.status = "DENIED";
        nom.save();
        console.log('DENY NOM', JSON.stringify(nom));
        // add a status/history entry
        let test = new this.$FeathersVuex.api.Status();
        test.reason = "Award denied";
        test.status = 'DENIED';
        test.changed_by = this.authUser.id;
        test.nomination_id = this.value.id;
        test.assigned_to = this.value.queue;
        test.save()
          .then(() => {
            const vm = this;
            this.snackmsg = "Denial saved";
            this.snackbar = true;
            setTimeout(() => { vm.snackbar = false; }, 5000)
          })
          .catch((err) => {
            const vm = this;
            this.snackmsg = "There was an error saving the denial";
            this.snackbar = true;
            setTimeout(() => { vm.snackbar = false; }, 5000)
            console.error(`Error saving note: ${err}`)
          })
        // console.log('DENY NOTE', JSON.stringify(test));
      }

      if (this.disposition === 'award') {
        // change the status of the nomination
        let nom = new this.$FeathersVuex.api.Nomination(this.cleanNom(this.value));
        nom.status = "AWARDED";
        nom.queue = 0;
        nom.save();
        console.log('AWARD NOM', JSON.stringify(nom));
        // add a status/history entry
        let test = new this.$FeathersVuex.api.Status();
        test.reason = "Awarded";
        test.status = 'AWARDED';
        test.changed_by = this.authUser.id;
        test.nomination_id = this.value.id;
        test.assigned_to = 0;
        test.save()
          .then(() => {
            const vm = this;
            this.snackmsg = "Award awarded";
            this.snackbar = true;
            setTimeout(() => { vm.snackbar = false; }, 5000)
          })
          .catch((err) => {
            const vm = this;
            this.snackmsg = "There was an error updating the award";
            this.snackbar = true;
            setTimeout(() => { vm.snackbar = false; }, 5000)
            console.error(`Error saving note: ${err}`)
          })
        // console.log('DENY NOTE', JSON.stringify(test));
      }

      if (this.disposition === 'suggest') {
        let awd = this.branchAwards.find(a => a.value === this.new_award).text;
        // change the status of the nomination & reassign to origin Q
        let nom = new this.$FeathersVuex.api.Nomination(this.cleanNom(this.value));
        nom.queue = nom.outq;
        nom.status = "HOLD";
        nom.save();
        console.log('SUGGEST NOM', JSON.stringify(nom));
        // add a status/history entry
        let test = new this.$FeathersVuex.api.Status();
        test.reason = `Alternate award suggested: ${awd}`;
        test.status = "SUGGEST";
        test.changed_by = this.authUser.id;
        test.nomination_id = this.value.id;
        test.assigned_to = this.value.outq;
        test.save();
        console.log('SUGGEST NOTE', JSON.stringify(test));
      }

      if (this.disposition === 'update') {
        let awd = this.branchAwards.find(a => a.value === this.new_award).text;
        // change the status of the nomination & reassign to origin Q
        let nom = new this.$FeathersVuex.api.Nomination(this.cleanNom(this.value));
        nom.queue = 1;
        nom.status = "SUBMITTED";
        nom.award = this.new_award;
        nom.save();
        console.log('UPDATE NOM', JSON.stringify(nom));
        // add a status/history entry
        let test = new this.$FeathersVuex.api.Status();
        test.reason = `Alternate award submitted: ${awd}`;
        test.status = "UPDATE";
        test.changed_by = this.authUser.id;
        test.nomination_id = this.value.id;
        test.assigned_to = 1;
        test.save();
        console.log('UPDATE NOTE', JSON.stringify(test));
      }

      if (this.disposition === 'forward') {
        let q = this.queues.find(q => q.value === this.new_queue).text;
        // change status of nomination
        let nom = new this.$FeathersVuex.api.Nomination(this.cleanNom(this.value));
        nom.status = 'AWARDS APPROVAL';
        nom.queue = this.new_queue;
        nom.save();
        console.log('FWD NOM', JSON.stringify(nom));
        // add status/history entry
        let test = new this.$FeathersVuex.api.Status();
        test.status = "FORWARDED";
        test.reason = `Approved and forwarded to: ${q}`;
        test.changed_by = this.authUser.id;
        test.nomination_id = this.value.id;
        test.assigned_to = this.value.outq;
        test.save();
        console.log('FWD NOTE', JSON.stringify(test));
      }

      if (this.disposition === 'approve') {
        // change status of nomination
        let nom = new this.$FeathersVuex.api.Nomination(this.cleanNom(this.value));
        nom.status = 'IA APPROVAL';
        // nom.queue = this.new_queue;
        nom.save();
        console.log('APPROVE NOM', JSON.stringify(nom));
        // add status/history entry
        let test = new this.$FeathersVuex.api.Status();
        test.status = "APPROVED";
        test.reason = 'Approved for awarding';
        test.changed_by = this.authUser.id;
        test.nomination_id = this.value.id;
        test.assigned_to = this.value.outq;
        test.save();
        console.log('APPROVE NOTE', JSON.stringify(test));
      }

      this.$emit('cancel'); 
    }
  },
  computed: {
    ...mapGetters({
			authUser: 'auth/user',
			queuesList: 'queues/list',
      awardsList: 'awards/list',
      precedenceList: 'precedence/list',
      usersGet: 'users/get'
    }),
    queues() {
      let all = [{value: 0, text: 'Personal'}].concat(
        this.queuesList.map(q => { return {value: q.id, text: q.name}})
      );
      return all;
    },
    branchAwards() {
      let possibles = this.precedenceList
        .filter(p => p.branch === this.value.nominee_branch)
        .map(p => {
          return p.award
        })
      return this.awardsList
        .filter(a => possibles.indexOf(parseInt(a.id)) > -1)
        .map(a => {
          return {
            text: a.name,
            value: a.id
          }
        })
    }
  }
}
</script>

<style scoped>
#review {
  position: relative;
  background-color: white;
  z-index: 101;
  width: 80%;
  margin: 2rem auto;
}

#data {
  display: grid;
  grid-template-columns: max-content auto;
  column-gap: 2rem;
  margin-bottom: 1rem;
}

.label {
  font-weight: 700;
}

pre {
  font-family: inherit;
}
</style>
