<template>
  <div id="dashboard">
		<div class="review-background" v-show="reviewing">
			<nomination-review v-model="reviewing" @cancel="stopReview"/>
		</div>
		<div class="head">
			<span class="page-title">Dashboard</span>
			<v-btn to="/nomination" color="blue" class="white--text">
				<v-icon>mdi-plus</v-icon>
				Add Nomination
			</v-btn>
		</div>
		<v-sheet
			class="mailbox"
			id="inbox"
			width="100%"
			elevation="2"
			shaped
			rounded
		>
			<div class="box-head">
				<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-inbox" viewBox="0 0 16 16">
					<path d="M4.98 4a.5.5 0 0 0-.39.188L1.54 8H6a.5.5 0 0 1 .5.5 1.5 1.5 0 1 0 3 0A.5.5 0 0 1 10 8h4.46l-3.05-3.812A.5.5 0 0 0 11.02 4H4.98zm9.954 5H10.45a2.5 2.5 0 0 1-4.9 0H1.066l.32 2.562a.5.5 0 0 0 .497.438h12.234a.5.5 0 0 0 .496-.438L14.933 9zM3.809 3.563A1.5 1.5 0 0 1 4.981 3h6.038a1.5 1.5 0 0 1 1.172.563l3.7 4.625a.5.5 0 0 1 .105.374l-.39 3.124A1.5 1.5 0 0 1 14.117 13H1.883a1.5 1.5 0 0 1-1.489-1.314l-.39-3.124a.5.5 0 0 1 .106-.374l3.7-4.625z"/>
				</svg>
				INBOX
			</div>
			<v-container>
				<div v-if="myInbox.length === 0">
					Nothing found
				</div>
				<div v-if="myInbox.length > 0">
					<v-data-table
						:items="myInbox"
						:headers="headers"
						:expanded.sync="inExpanded"
						@click:row.self="handleInClick"
						show-expand
						dense
					>
						<template v-slot:expanded-item="{ headers, item }">
							<td :colspan="headers.length">
								<v-container>
									<v-row no-gutters>
										<v-col cols="3">
											Nominated By
										</v-col>
										<v-col cols="9">
											{{ nominatorName(item) || '' }}
										</v-col>
									</v-row>
									<v-row no-gutters>
										<v-col cols="3">
											Nomination Text
										</v-col>
										<v-col cols="9">
											<pre>{{ item.nomination_text || '' }}</pre>
										</v-col>
									</v-row>
									<v-row no-gutters>
										<v-col cols="3">Nomination History</v-col>
										<v-col cols="9">
											<nomination-history v-for="h in history[item.id]" :key="h.id" :history="h" />
										</v-col>
									</v-row>
									<v-row v-if="item.status !== 'AWARDED'">
										<v-col class="d-flex flex-row justify-end">
											<v-btn
												elevation="2"
												small
												@click.stop="review(item)"
											>
												Review
											</v-btn>
										</v-col>
									</v-row>
								</v-container>
							</td>
						</template>
					</v-data-table>
				</div>
			</v-container>
		</v-sheet>

		<v-sheet
			class="mailbox"
			id="outbox"
			width="100%"
			elevation="2"
			shaped
			rounded
		>
			<div class="box-head">
				<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-inbox" viewBox="0 0 16 16">
					<path d="M4.98 4a.5.5 0 0 0-.39.188L1.54 8H6a.5.5 0 0 1 .5.5 1.5 1.5 0 1 0 3 0A.5.5 0 0 1 10 8h4.46l-3.05-3.812A.5.5 0 0 0 11.02 4H4.98zm9.954 5H10.45a2.5 2.5 0 0 1-4.9 0H1.066l.32 2.562a.5.5 0 0 0 .497.438h12.234a.5.5 0 0 0 .496-.438L14.933 9zM3.809 3.563A1.5 1.5 0 0 1 4.981 3h6.038a1.5 1.5 0 0 1 1.172.563l3.7 4.625a.5.5 0 0 1 .105.374l-.39 3.124A1.5 1.5 0 0 1 14.117 13H1.883a1.5 1.5 0 0 1-1.489-1.314l-.39-3.124a.5.5 0 0 1 .106-.374l3.7-4.625z"/>
				</svg>
				OUTBOX
			</div>
			<v-container>
				<div v-if="myOutbox.length === 0">
					Nothing found
				</div>
				<div v-if="myOutbox.length > 0">
					<v-data-table
						:items="myOutbox"
						:headers="headers"
						:expanded.sync="outExpanded"
						@click:row="handleOutClick"
						show-expand
						dense
					>
						<template v-slot:expanded-item="{ headers, item }">
							<td :colspan="headers.length">
								<v-container>
									<v-row no-gutters>
										<v-col cols="6">
											Nominated By
										</v-col>
										<v-col cols="6">
											{{ nominatorName(item) }}
										</v-col>
									</v-row>
									<v-row no-gutters>
										<v-col cols="6">
											Nomination Text
										</v-col>
										<v-col cols="6">
											<pre>{{ item.nomination_text || '' }}</pre>
										</v-col>
									</v-row>
								</v-container>
							</td>
						</template>
					</v-data-table>
				</div>
			</v-container>
		</v-sheet>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import NominationReview from '../components/NominationReview.vue';
import NominationHistory from '../components/NominationHistory.vue';

export default {
  name: 'Dashboard',
	components: { NominationReview, NominationHistory },
	mounted() {
		// retrieve awards
		this.$store.dispatch('awards/find', {});
		// retrieve precedence lists
		this.$store.dispatch('precedence/find', {});
		// retreive queue-assignments
		this.$store.dispatch('queue-assignments/find', {});
		// get nominations in those queues (not for me)
		this.$store.dispatch('nominations/find', {});
		// get queues in those queues (not for me)
		this.$store.dispatch('queues/find', {});
		this.$store.dispatch('users/find', {});
	},
	// updated() {
	// 	console.log('updated');
	// 	this.$store.dispatch('nominations/find', {});
	// },
	computed: {
		...mapGetters({
			authUser: 'auth/user',
			nominationsList: 'nominations/list',
			usersGet: 'users/get',
			awardsGet: 'awards/get',
			queuesGet: 'queues/get',
			queuesList: 'queues/list',
			statusFind: 'status/find',
			queueAssignmentsGet: 'queue-assignments/get',
			queueAssignmetsList: 'queue-assignments/list'
		}),
		queueList() {
			return this.queuesList
				.map(q => {
					return q.id;
				})
		},
		myQueues() {
			// const { QueueAssignment } = this.$FeathersVuex.api;
			// return QueueAssignment.findInStore({}).data.map(x => x.queue);
			return this.queueAssignmetsList
				.filter(q => q.member === this.authUser.id && q.active === 1)
				.map(q => {
					return q.queue;
				})
		},
		myInbox() {
			return this.nominationsList
				.filter(q => { return this.myQueues.indexOf(q.queue) > -1 ||
								(q.queue === 0 && q.nominator_id == this.authUser.id && q.status !== "AWARDED") })
				.map(n => {

					let award = this.awardsGet(parseInt(n.award));
					let inq = this.queuesGet(n.queue) || {name: 'Personal'};
					let outq = this.queuesGet(n.outq) || {name: 'Personal'};
					this.nominationHistory(n.id);
					return {
						...n,
						name: `${n.nominee_first_name} ${n.nominee_last_name}`,
						award_name: award || '',
						in: inq.name || '',
						out: outq.name || ''
					}
				});
		},
		myOutbox() {
			// let u = this.$store.getters['users/get'](parseInt(n.nominator_id));
			return this.nominationsList
				.filter(q => { 
					return this.myQueues.indexOf(q.outq) > -1 ||
								(q.outq === 0 && q.nominator_id == this.authUser.id)
				})
				.map(n => {
				let award = this.awardsGet(parseInt(n.award));
				let inq = this.queuesGet(n.queue) || {name: 'Personal'};
				let outq = this.queuesGet(n.outq) || {name: 'Personal'};
				// this.$store.dispatch('users/get', parseInt(n.nominator_id));
				return {
					...n,
					name: `${n.nominee_first_name} ${n.nominee_last_name}`,
					award_name: award || '',
					in: inq.name || '',
					out: outq.name || ''
				}
			})
		},
		toAward() {
			return this.myInbox.filter(n => n.status === "IA APPROVAL");
		}
	},
	methods: {
		review(item) {
			// console.log("REVIEW", JSON.stringify(item.id));
			this.reviewing = item;
		},
		stopReview() {
			this.nominationHistory(this.reviewing.id);
			this.reviewing = null;
		},
		handleInClick(evt) {
			// find the index of this item in the expanded list, if it's in there
			let idx = this.inExpanded.findIndex(x => x.id === evt.id);
			if (idx > -1) {
				// remove the item from the expanded list if it's currently in there
				this.inExpanded.splice(idx, 1);
			} else {
				// console.log("EVT", JSON.stringify(evt));
				// otherwise, add the item to the list
				evt['test'] = 1;
				// evt['nominator'] = User.findInStore({query: {id: parseInt(evt.nominator_id)}}).data[0];
				// evt['nominator'] = this.usersGet(evt.nominator_id);
				this.inExpanded.push(evt);
			}
		},
		handleOutClick(evt) {
			// find the index of this item in the expanded list, if it's in there
			let idx = this.outExpanded.findIndex(x => x.id === evt.id);
			if (idx > -1) {
				// remove the item from the expanded list if it's currently in there
				this.outExpanded.splice(idx, 1);
			} else {
				// otherwise, add the item to the list
				evt['test'] = 1;
				// evt['nominator'] = User.findInStore({query: {id: parseInt(evt.nominator_id)}}).data[0];

				evt['nominator'] = this.usersGet(evt.nominator_id);
				this.outExpanded.push(evt);
			}
		},
		nominationHistory(item) {
			let vm = this;
			this.$store.dispatch('status/find', 
				{query: { nomination_id: item }})
				.then(result => {
					result.map(function (h) {
						h.changed_by = vm.usersGet(h.changed_by)
					})
					// console.log(result);
					vm.history[item] = result;
				})
		},
		nominatorName(item) {
			if (!item) return '';
			// console.log(JSON.stringify(item));
			// let nominator = this.$store.getters['users/get'](parseInt(item.nominator_id));
			// console.log(JSON.stringify(nominator));
			return `${item.nominator.first_name} ${item.nominator.last_name}`
		}
	},
	data () {
		return {
			inExpanded: [],
			outExpanded: [],
			reviewing: null,
			history: {},
			headers: [
				{ text: 'Nominee Name', value: 'name' },
				{ text: 'Member ID', value: 'nominee_member_id' },
				{ text: 'Award', value: 'award_name.name' },
				{ text: 'OutQ', value: 'out' },
				{ text: 'InQ', value: 'in' },
				{ text: 'Status', value: 'status' },
			]
		}
	}
}
</script>

<style scoped>
pre {
	font-family: inherit;
}
.head {
  display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-bottom: 1rem;
}

.page-title {
  font-size: 1.4em;
	font-weight: 700;
}

.section {
  border: 1px solid #888;
	border-radius: 25px;
	margin-bottom: 12px;
	padding: 4px
}

.box-head {
	padding: 6px;
}

.mailbox {
	margin-bottom: 12px;
}

.review-background {
	background: rgba(0, 0, 0, 0.5);
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 100;
}
</style>
